import router from "../../router/index.js";

let baseurl = '';
if (process.env.NODE_ENV === 'development') {
	// 本地
	baseurl = 'https://www.xa.aaasxhd.com/';
} else {
	// 线上
	baseurl = '/';
}

export default {
	// 跳转页面
	get_link(a, b) {
		router.push({
			path: a,
			query: b
		})
		window.scroll(0, 0);
	},
	// 图片上传
	get_up_img() {
		return baseurl + 'api/admin/common/uploadImage';
	},
	// 域名
	get_host() {
		return baseurl;
	},
	// excel
	get_excel() {
		return baseurl + 'api/admin/text/exportExcel';
	},
	// 获取当前年月日
	addDate() {
		const nowDate = new Date();
		const date = {
			year: nowDate.getFullYear(),
			month: nowDate.getMonth() + 1,
			date: nowDate.getDate(),
			hour: nowDate.getHours(), // 时
			minute: nowDate.getMinutes(), // 分
		}
		const newmonth = date.month > 10 ? date.month : '0' + date.month
		const day = date.date > 10 ? date.date : '0' + date.date
		const hour = date.hour > 10 ? date.hour : '0' + date.hour
		const minute = date.minute > 10 ? date.minute : '0' + date.minute
		return date.year + '-' + newmonth + '-' + day
	},
	// 区分公司
	get_LostName() {
		let info = {}

		if (location.hostname === 'xa.aaasxhd.com' || location.hostname === 'www.xa.aaasxhd.com') {
			// 国际物流
			// info = {
			// 	logo: require('../../assets/login/06.png'),
			// 	logo1: require('../../assets/login/02.png'),
			// 	zhangImg: require('../../assets/zhang.png'),
			// 	wxImg: require('../../assets/wx/wx@2x.png'),
			// 	nameText: '北京世鑫惠达国际货运代理有限公司',
			// 	loginName: '国际物流作业系统',
			// 	companyInfo: {
			// 		name: '北京世鑫惠达国际货运代理有限公司',
			// 		bank: '中国银行顺义马坡支行', //开户银行名称
			// 		bankAaccount: '3363 7090 1612', // 卡号
			// 		address: '北京市顺义区华英园9号5层5067',
			// 		phone: '010-69467872',
			// 		num: '91110113318261788P', // 税务登记号
			// 		bankAllName: '中国农业银行股份有限公司北京宏城花园支行',
			// 		bankAllAaccount: '11-121201040013359', // 银行账户
			// 		bank1: '中国农业银行股份有限公司北京空港支行',
			// 		bankAaccount1: '1112 0901 0400 19576'
			// 	}
			// }
			info = {
				logo: require('../../assets/login/11.png'),
				logo1: require('../../assets/login/11.png'),
				zhangImg: require('../../assets/zhang1.png'),
				wxImg: '',
				nameText: '北京信安优能科技有限公司',
				loginName: '信安作业系统',
				companyInfo: {
					name: '北京信安优能科技有限公司',
					bank: '中国农业银行北京科技园支行',
					bankAaccount: '11121501040019565', // 卡号
					address: '北京市顺义区仁和镇铁东路法医街5号',
					phone: '010-69467872',
					num: '91110113MA017RYC42', // 税务登记号
					bankAllName: '中国农业银行北京科技园支行',
					bankAllAaccount: '11121501040019565', // 银行账户
					bank1: '',
					bankAaccount1: ''
				}
			}
		} else {
			// info = {
			// 	logo: require('../../assets/login/07.png'),
			// 	logo1: require('../../assets/login/10.png'),
			// 	zhangImg: require('../../assets/zhang1.png'),
			// 	wxImg: '',
			// 	nameText: '北京国臻佳联科技股份有限公司',
			// 	loginName: '国臻佳联作业系统',
			// 	companyInfo: {
			// 		name: '北京国臻佳联科技股份有限公司',
			// 		bank: '中国银行北京马坡支行',
			// 		bankAaccount: '3350 7094 7112', // 卡号
			// 		address: '北京市顺义区华英园9号5层5028',
			// 		phone: '010-69467872',
			// 		num: '91110113MA01DGAD2Q', // 税务登记号
			// 		bankAllName: '中国银行北京马坡支行',
			// 		bankAllAaccount: '3350 7094 7112', // 银行账户
			// 		bank1: '',
			// 		bankAaccount1: ''
			// 	}
			// }
			info = {
				logo: require('../../assets/login/11.png'),
				logo1: require('../../assets/login/11.png'),
				zhangImg: require('../../assets/zhang1.png'),
				wxImg: '',
				nameText: '北京信安优能科技有限公司',
				loginName: '信安作业系统',
				companyInfo: {
					name: '北京信安优能科技有限公司',
					bank: '中国农业银行北京科技园支行',
					bankAaccount: '11121501040019565', // 卡号
					address: '北京市顺义区仁和镇铁东路法医街5号',
					phone: '010-69467872',
					num: '91110113MA017RYC42', // 税务登记号
					bankAllName: '中国农业银行北京科技园支行',
					bankAllAaccount: '11121501040019565', // 银行账户
					bank1: '',
					bankAaccount1: ''
				}
			}
		}

		console.log(location.hostname)

		return info;
	}
}
